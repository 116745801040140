import _get from "lodash/get";
import { Alert } from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import messaging from "./notification/init-fcm";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import { Offline } from "react-detect-offline";
import { toBoolean } from "./utils/validators";
import { getIp } from "./actions/app/appActions";
import { setNotifications } from "./actions/pushNotification/pushNotificationActions";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const PaycheckTemplate = React.lazy(() => import("./views/Templates/Paycheck"));
const InvoiceTemplate = React.lazy(() => import("./views/Templates/Invoice"));

class App extends Component {
  componentDidMount() {
    try {
      this.props.getIp();
      messaging.onMessage(this.handleFireBaseMsg);

      // setTimeout(() => {
      //   this.speakNow(
      //     "At pick up and waiting for dock  for Load# 1187 By: Nick"
      //   );
      // }, 5000);
    } catch (e) {}
  }

  speakNow = message => {
    let voices = [];
    const voiceNotif = toBoolean(`${this.props.voiceNotif}`);
    if (!voiceNotif) {
      return;
    }
    try {
      let synth = window.speechSynthesis;
      setTimeout(() => {
        voices = synth.getVoices();
        let utterThis = new SpeechSynthesisUtterance(`${message}`);
        utterThis.voice = voices.find(item => {
          return item.name === "Samantha";
          // return item.name === "Victoria";
          // return item.name === "Google US English";
        });
        synth.speak(utterThis);
      }, 1000);
    } catch (e) {}
  };

  handleFireBaseMsg = payload => {
    const title = _get(payload, "notification.title");
    const message = _get(payload, "notification.body");
    const notifType = _get(payload, "data.type", "info");
    // console.log(payload);
    // let notifications = this.props.notifications || [];
    //
    // notifications.push({ title, message });

    // console.log('notifications ', notifications);
    // this.props.setNotifications(notifications);
    // this.speakNow(message);
    store.addNotification({
      title,
      message,
      type: notifType,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 10000,
        onScreen: true
      }
    });
  };

  render() {
    // if (!this.props.preCheck) {
    //   return null;
    // }
    // console.log(this.props.voiceNotif);
    return (
      <React.Fragment>
        <Offline>
          <Alert
            style={{ margin: 0, fontSize: 18, fontWeight: 600 }}
            className="text-center text-uppercase"
            color="danger"
          >
            <i className="fa fa-warning" /> You're offline right now. Check your
            internet connection.
          </Alert>
        </Offline>
        <Router>
          <ReactNotification />
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <Login {...props} />}
              />

              <Route
                exact
                path="/register"
                name="Register Page"
                render={props => <Register {...props} />}
              />
              <Route exact path="/reset-password" name="Reset Password" />
              <Route
                exact
                path="/template/paycheck/:id"
                name="Paycheck"
                render={props => <PaycheckTemplate {...props} />}
              />
              <Route
                exact
                path="/template/invoice/:id"
                name="Paycheck"
                render={props => <InvoiceTemplate {...props} />}
              />

              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={props => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    voiceNotif: _get(state, "authData.business.settings.voiceNotif", false),
    notifications: _get(state, "pushNotificationData.notifications", [])
  };
};

export default connect(
  mapStateToProps,
  { setNotifications, getIp }
)(App);
